import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor, Descendant, Transforms } from 'slate';
import { Slate, Editable, RenderLeafProps } from 'slate-react';
import Leaf from './renders/Leaf';
import { CustomSlateNodeType, LySlatePlugin, PluginParseUtils } from './types';
import lySlatePluginParser from './utils/lySlatePluginParser';

const StyledEditable = styled(Editable)`
  height: 100px;
  * {
    word-break: break-all;
  }
`;

export const defaultValue: Descendant[] = [
  {
    type: CustomSlateNodeType.PARAGRAPH,
    children: [
      {
        text: '',
      },
    ],
  },
];

export interface TemplateEditorProps {
  value?: Descendant[];
  onChange: (value: Descendant[]) => void;
  lySlatePlugins?: LySlatePlugin[];
  placeholder?: string;
  readOnly?: boolean;
  children?: (editable: JSX.Element, props: TemplateEditorProps) => JSX.Element;
  onBlur?: Parameters<typeof Editable>[0]['onBlur'];
}

function TemplateEditor(props: TemplateEditorProps) {
  const {
    value = defaultValue,
    onChange,
    placeholder,
    readOnly,
    lySlatePlugins = [],
    children,
    onBlur,
  } = props;

  const [{ editor, renderElement }] = useState<PluginParseUtils>(() =>
    lySlatePluginParser(lySlatePlugins),
  );

  const handleChange = useCallback(
    (newValue: Descendant[]) => {
      if (!readOnly && value !== newValue) {
        onChange(newValue);
      }
    },
    [onChange, readOnly, value],
  );

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    return <Leaf {...props} />;
  }, []);

  useEffect(() => {
    // 因为第一次传值进来的时候,不会自动normalize,所以对第一个有效value手动normalize
    Editor.normalize(editor, { force: true });
  }, [editor]);

  const compositionRef = useRef(false);

  const editable = (
    <StyledEditable
      placeholder={placeholder}
      readOnly={readOnly}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      onCompositionStart={() => {
        compositionRef.current = true;
      }}
      onCompositionEnd={() => {
        compositionRef.current = false;
      }}
      onKeyDown={(event) => {
        if (compositionRef.current) {
          event.preventDefault();

          return;
        }

        if (event.key === 'Enter') {
          event.preventDefault();
          Transforms.insertText(editor, '\n');
        }
      }}
      onBlur={onBlur}
    />
  );

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      {children ? children(editable, props) : editable}
    </Slate>
  );
}

export default TemplateEditor;

export * from './components';
export * from './lySlatePlugins';
export * from './toolbarPlugins';
export * from './utils';
export * from './types';
