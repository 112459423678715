import withConcept from './slatePlugins/withConcept';
import TagConcept from '../renders/TagConcept';
import { CustomSlateNodeType, LySlatePlugin } from '../types';

const conceptPlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.CONCEPT,
  slatePlugin: withConcept,
  processComponent: (props) => {
    return <TagConcept {...props} />;
  },
};

export default conceptPlugin;
