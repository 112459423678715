import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { LySlatePlugin, PluginParseUtils } from '../types';
import connectRenderElementMap from '../components/connectRenderElementMap';
import { pipe } from './pipe';

const defaultPlugins = [withHistory, withReact] as const;

function lySlatePluginParser(lySlatePlugins: LySlatePlugin[]): PluginParseUtils {
  const plugins = [];
  const renderElementMap: Record<string, LySlatePlugin['processComponent']> = {};
  for (const lySlatePlugin of lySlatePlugins) {
    const { elementType, slatePlugin, processComponent } = lySlatePlugin;
    plugins.push(slatePlugin);
    renderElementMap[elementType] = processComponent;
  }

  const editor = pipe(createEditor(), ...defaultPlugins, ...plugins);

  const renderElement = connectRenderElementMap(renderElementMap);

  return {
    editor,
    renderElement,
  };
}

export default lySlatePluginParser;
