import React from 'react';
import styled from '@emotion/styled';
import { Form, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { FormItemProps } from 'antd/es/form';

import { render, RenderProps } from '../Render';
import { useSmartField, SmartFieldProps } from '../../hooks';

export type FormFieldProps<TValue> = Partial<
  RenderProps<React.ElementType<SmartFieldProps<TValue>>, SmartFieldProps<TValue>>
> &
  Omit<FormItemProps, 'children'> & {
    name: string;
    label?:
      | React.ReactNode
      | ((fieldProps: SmartFieldProps<TValue>, props: FormFieldProps<TValue>) => React.ReactNode);
    description?:
      | React.ReactNode
      | ((fieldProps: SmartFieldProps<TValue>, props: FormFieldProps<TValue>) => React.ReactNode);
    tips?:
      | React.ReactNode
      | ((fieldProps: SmartFieldProps<TValue>, props: FormFieldProps<TValue>) => React.ReactNode);
    help?:
      | React.ReactNode
      | ((fieldProps: SmartFieldProps<TValue>, props: FormFieldProps<TValue>) => React.ReactNode);
    validateStatus?:
      | FormItemProps['validateStatus']
      | ((
          fieldProps: SmartFieldProps<TValue>,
          props: FormFieldProps<TValue>,
        ) => FormItemProps['validateStatus']);
    validate?: (value: TValue) => undefined | string | Promise<any>;
  };

const { Item: FormItem } = Form;

const StyledLabel = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledHelp = styled(QuestionCircleOutlined)`
  margin-left: 4px;
  cursor: help;
`;

function refine<T = any>(value: any, ...args: any[]): T {
  return typeof value === 'function' ? value(...args) : value;
}

export function simpleHelp<T = any>(fieldProps: SmartFieldProps<T>, props: FormFieldProps<T>) {
  const { tips: tipsProp } = props;
  const { meta } = fieldProps;

  const { touched, error } = meta;

  if (touched && error) {
    if (typeof error === 'string') {
      return error;
    }

    const { __errors: errors } = error;

    if (errors) {
      if (Array.isArray(errors)) {
        return (errors as any[]).join(', ');
      }

      return Object.values(errors).join(', ');
    }
  }

  const tips = refine<FormFieldProps<T>['tips']>(tipsProp, fieldProps, props);

  if (tips) {
    return tips;
  }

  return undefined;
}

export function simpleValidateStatus<T = any>(fieldProps: SmartFieldProps<T>) {
  const { meta } = fieldProps;

  const { touched, error } = meta;

  if (touched) {
    if (error) {
      if (typeof error === 'string' || (error as any).__errors) {
        return 'error';
      }
    }

    return 'success';
  }

  return undefined;
}

function FormField<TValue = any>(props: FormFieldProps<TValue>) {
  const {
    as,
    render: renderProp,
    name,
    label: labelProp,
    description: descriptionProp,
    tips,
    help: helpProp = simpleHelp,
    validateStatus: validateStatusProp = simpleValidateStatus,
    validate,
    children,
    ...otherProps
  } = props;

  const smartFieldProps = useSmartField(name, validate);
  const { field, meta, helpers } = smartFieldProps;

  const label = refine<FormItemProps['label']>(labelProp, smartFieldProps, props);
  const description = refine<FormItemProps['label']>(descriptionProp, smartFieldProps, props);
  const help = refine<FormItemProps['help']>(helpProp, smartFieldProps, props);
  const validateStatus = refine<FormItemProps['validateStatus']>(
    validateStatusProp,
    smartFieldProps,
    props,
  );
  const title =
    label && description ? (
      <StyledLabel>
        <span>{label}</span>
        <Tooltip title={description}>
          <StyledHelp />
        </Tooltip>
      </StyledLabel>
    ) : (
      label
    );

  return (
    <FormItem {...otherProps} label={title} help={help} validateStatus={validateStatus}>
      {render({
        as,
        render: renderProp,
        children,
        field,
        meta,
        helpers,
      })}
    </FormItem>
  );
}

export default FormField;
