import withDividingLinde from './slatePlugins/withDividingLine';
import DividingLine from '../renders/DividingLine';
import { CustomSlateNodeType, LySlatePlugin } from '../types';

const dividingLinePlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.DIVIDING_LINE,
  slatePlugin: withDividingLinde,
  processComponent: (props) => {
    return <DividingLine {...props} />;
  },
};

export default dividingLinePlugin;
