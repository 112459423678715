export function pipe<T0>(x0: T0): T0;
export function pipe<T0, T1>(x0: T0, f1?: (x: T0) => T1): T1;
export function pipe<T0, T1, T2>(x0: T0, f1?: (x: T0) => T1, f2?: (x: T1) => T2): T2;
export function pipe<T0, T1, T2, T3>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
): T3;
export function pipe<T0, T1, T2, T3, T4>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
): T4;
export function pipe<T0, T1, T2, T3, T4, T5>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
): T5;
export function pipe<T0, T1, T2, T3, T4, T5, T6>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
): T6;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
): T7;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7, T8>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
  f8?: (x: T7) => T8,
): T8;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7, T8, T9>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
  f8?: (x: T7) => T8,
  f9?: (x: T8) => T9,
): T9;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
  f8?: (x: T7) => T8,
  f9?: (x: T8) => T9,
  f10?: (x: T9) => T10,
): T10;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
  f8?: (x: T7) => T8,
  f9?: (x: T8) => T9,
  f10?: (x: T9) => T10,
  f11?: (x: T10) => T11,
): T11;
export function pipe<T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12>(
  x0: T0,
  f1?: (x: T0) => T1,
  f2?: (x: T1) => T2,
  f3?: (x: T2) => T3,
  f4?: (x: T3) => T4,
  f5?: (x: T4) => T5,
  f6?: (x: T5) => T6,
  f7?: (x: T6) => T7,
  f8?: (x: T7) => T8,
  f9?: (x: T8) => T9,
  f10?: (x: T9) => T10,
  f11?: (x: T10) => T11,
  f12?: (x: T11) => T12,
): T12;

export function pipe(x: any, ...fns: any[]) {
  return fns.reduce((y: any, fn) => fn(y), x);
}
