import { BaseEditor, Editor } from 'slate';
import { RenderElementProps, ReactEditor } from 'slate-react';

export type SlatePlugin = (editor: Editor) => Editor;
export type RenderElement = (props: RenderElementProps) => JSX.Element;

export interface LySlatePlugin {
  elementType: string;
  slatePlugin: SlatePlugin;
  processComponent: React.ComponentType<RenderElementProps>;
}

export interface PluginParseUtils {
  editor: Editor;
  renderElement: (props: RenderElementProps) => JSX.Element;
}

export interface LabelInfo {
  label: string;
}

export interface ConceptInfo extends LabelInfo {}

export interface ImageInfo {
  imageId: string | undefined;
  [key: string]: any;
}

export interface ParamTextInfo extends LabelInfo {
  canEditor: boolean;
}

export interface ParamHolderInfo extends LabelInfo {}

export enum CustomSlateNodeType {
  PARAGRAPH = 'paragraph',
  TEXT = 'text',
  DIVIDING_LINE = 'eos',
  EMOTICON = 'emoticon',
  CONCEPT = 'concept',
  IMAGE = 'image',
  PARAMTEXT = 'paramText',
  PARAMHOLDER = 'paramHolder',
}

// 叶子元素
export interface CustomText extends Partial<Record<LeafFormat, boolean>> {
  type?: 'text';
  text: string;
}

export interface ConceptElement extends ConceptInfo {
  type: CustomSlateNodeType.CONCEPT;
  children: CustomText[];
}
export interface ParamTextElement extends ParamTextInfo {
  type: CustomSlateNodeType.PARAMTEXT;
  children: CustomText[];
}
export interface ParamHolderElement extends ParamHolderInfo {
  type: CustomSlateNodeType.PARAMHOLDER;
  children: CustomText[];
}

// 块级元素
export interface ParagraphElement {
  type: CustomSlateNodeType.PARAGRAPH;
  children: Array<CustomText | ConceptElement | ParamTextElement | ParamHolderElement | CustomText>;
}
export interface DividingLineElement {
  type: CustomSlateNodeType.DIVIDING_LINE;
  children: CustomText[];
}
export interface ImageElement {
  type: CustomSlateNodeType.IMAGE;
  images: ImageInfo[];
  children: CustomText[];
}

export type LeafFormat = 'bold' | 'italic';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element:
      | ParagraphElement
      | ImageElement
      | DividingLineElement
      | ConceptElement
      | ParamTextElement
      | ParamHolderElement;
    Text: CustomText;
  }
}
