import { CustomSlateNodeType, LySlatePlugin } from '../types';
import TagParamHolder from '../renders/TagParamHolder';
import withParamHolder from './slatePlugins/withParamHolder';

const paramHolderPlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.PARAMHOLDER,
  slatePlugin: withParamHolder,
  processComponent: (props) => {
    return <TagParamHolder {...props} />;
  },
};

export default paramHolderPlugin;
