import React from 'react';
import { RenderElementProps } from 'slate-react';
import DefaultElement from '../renders/Paragraph';
import { CustomSlateNodeType, LySlatePlugin } from '../types';

interface SlateElementRenderProps extends RenderElementProps {
  DefaultElementRenderComponent?: React.ComponentType<any>;
  renderElementMap: Record<string, LySlatePlugin['processComponent']>;
}

function SlateElementRender(props: SlateElementRenderProps) {
  const {
    DefaultElementRenderComponent,
    renderElementMap,
    element: { type },
  } = props;

  if (type === CustomSlateNodeType.PARAGRAPH) {
    return DefaultElementRenderComponent ? (
      <DefaultElementRenderComponent {...props} />
    ) : (
      <DefaultElement {...props} />
    );
  }
  if (type && typeof type === 'string') {
    const CurrentComponent = renderElementMap[type];
    return CurrentComponent ? <CurrentComponent {...props} /> : null;
  }
  return <DefaultElement {...props} />;
}

export default SlateElementRender;
