import { css, cx } from '@emotion/css';
import { Tag } from 'antd';
import { useFocused, useSelected } from 'slate-react';
import { ConceptElement } from '../types';

const styles = {
  container: css`
    display: inline-block;
    margin: 0 4px;
  `,
  tag: css`
    background-color: #f0f2f5;
    border: 0;
    color: #515359;
    margin: 0;
  `,
  selected: css`
    box-shadow: 0 0 0 3px #b4d5ff;
  `,
};

interface Props {
  element: any;
  attributes: any;
  children: any;
}

function TagConcept(props: Props) {
  const { element, attributes, children } = props;
  const { label } = element as ConceptElement;
  const focused = useFocused();
  const selected = useSelected();

  return (
    <div {...attributes} contentEditable={false} className={styles.container}>
      <Tag color="#E6E4F2" className={cx(styles.tag, focused && selected && styles.selected)}>
        {label}
      </Tag>
      {children}
    </div>
  );
}

export default TagConcept;
