import { useState, useMemo } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

function useModal<T>(defaultVisible: boolean = false, defaultMeta?: T) {
  const [visible, setVisible] = useState(defaultVisible);
  const [meta, setMeta] = useState(defaultMeta);

  const { update, show, hide } = useMemo(() => {
    function update(visible: boolean, meta?: T) {
      batchedUpdates(() => {
        setVisible(visible);
        setMeta(meta);
      });
    }

    function show(meta?: T) {
      batchedUpdates(() => {
        setVisible(true);
        setMeta(meta);
      });
    }

    function hide(meta?: T) {
      batchedUpdates(() => {
        setVisible(false);

        if (meta !== undefined) {
          setMeta(meta);
        }
      });
    }

    return {
      update,
      show,
      hide,
    };
  }, []);

  return {
    visible,
    meta,
    update,
    show,
    hide,
  };
}

export default useModal;
