import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { Form, Input, Popover, Tag, Tooltip } from 'antd';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
// import useProfile from 'hooks/business/useProfile';
// import { URL_PATTERN } from 'views/viewConfig';
// import { getShortLink } from 'services/api/activeMarketing';

const { Search } = Input;

type ValidateStatuses = 'success' | 'warning' | 'error' | 'validating' | '';
type FormItemError = { validateStatus: ValidateStatuses; errorMsg: string | null };

const INIT_ERROR: FormItemError = { validateStatus: 'success', errorMsg: null };

const styles = {
  selected: css`
    box-shadow: 0 0 0 3px #b4d5ff;
  `,
  tag: css`
    color: #705cf2;
    margin: 0 4px;
    span {
      color: #705cf2 !important;
    }
  `,
  defaultTag: css`
    background-color: #f0f2f5;
    border: 0;
    color: #515359;
    span {
      color: #515359 !important;
    }
  `,
};

interface Props {
  element: any;
  attributes: any;
  children: any;
}

const TagParamText = (props: Props) => {
  // const { user } = useProfile();
  const { element, attributes, children } = props;
  const { label, canEditor } = element;

  const editor = useSlate();

  const [inputUrl, setInputUrl] = useState<string>();
  const [linkInputError, setLinkInputError] = useState<FormItemError>(INIT_ERROR);
  const [popVisible, setPopVisible] = useState<boolean>(false);

  const url2ShortLink = useCallback((value: string) => {
    if (!value) {
      setLinkInputError({
        validateStatus: 'error',
        errorMsg: '请输入链接',
      });
      // return;
    }
    // if (!URL_PATTERN.test(value)) {
    //   setLinkInputError({
    //     validateStatus: 'error',
    //     errorMsg: '链接格式有误，请重新输入',
    //   });
    //   return;
    // }
    // getShortLink(value)
    //   .then((response) => {
    //     const {
    //       data: { data },
    //     } = response;
    //     const currentPath = ReactEditor.findPath(editor, element);
    //     Transforms.setNodes(
    //       editor,
    //       {
    //         ...element,
    //         label: data,
    //       },
    //       { at: currentPath },
    //     );
    //     message.success('生成短链成功');
    //     setInputUrl('');
    //   })
    //   .catch(() => {
    //     message.error('生成短链失败：输入的URL链接非法或不能访问');
    //   })
    //   .finally(() => {
    //     setPopVisible(false);
    //   });
  }, []);

  const handleInsertAddressParamText = useCallback(() => {
    // const storeId = user?.storeId;
    // if (storeId) {
    //   getShortLink(`https://shop${storeId}.taobao.com`)
    //     .then((response) => {
    //       const {
    //         data: { data },
    //       } = response;
    //       const currentPath = ReactEditor.findPath(editor, element);
    //       Transforms.setNodes(
    //         editor,
    //         {
    //           ...element,
    //           label: data,
    //         },
    //         { at: currentPath },
    //       );
    //     })
    //     .catch(() => {
    //       message.error('获取店铺地址失败');
    //     });
    // }
  }, []);

  const initError = useCallback(() => {
    setLinkInputError(INIT_ERROR);
  }, []);

  const handleInputChange = useCallback(
    (e: any) => {
      initError();
      setInputUrl(e.target.value);
    },
    [initError],
  );

  const handleCloseTag = useCallback(() => {
    const currentPath = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: currentPath });
  }, [editor, element]);

  const handleVisibleChange = useCallback((visible: boolean) => {
    setPopVisible(visible);
  }, []);
  const showPopover = useCallback(() => {
    setPopVisible(true);
  }, []);

  useEffect(() => {
    if (label === '店铺地址') {
      handleInsertAddressParamText();
    }
  }, [handleInsertAddressParamText, label]);

  const popContent = useMemo(
    () => (
      <Form.Item validateStatus={linkInputError.validateStatus} help={linkInputError.errorMsg}>
        <Search
          value={inputUrl}
          onChange={handleInputChange}
          onSearch={url2ShortLink}
          onBlur={initError}
          onClick={initError}
          enterButton="生成短链"
          placeholder="请输入链接..."
          allowClear
        />
      </Form.Item>
    ),
    [
      handleInputChange,
      initError,
      inputUrl,
      linkInputError.errorMsg,
      linkInputError.validateStatus,
      url2ShortLink,
    ],
  );

  const tagColor = useMemo(() => {
    const initialContent = ['商品链接', '活动链接'];
    return initialContent.includes(label)
      ? null
      : {
          color: '#E6E4F2',
          className: styles.tag,
        };
  }, [label]);

  const destTag = useMemo(() => {
    if (!canEditor) {
      return (
        <span contentEditable={false}>
          <Tag
            color="f0f2f5"
            className={styles.defaultTag}
            closable
            onClose={handleCloseTag}
            {...attributes}
          >
            {label}
            {children}
          </Tag>
        </span>
      );
    }
    return (
      <span contentEditable={false}>
        <Popover
          content={popContent}
          title="商品/活动链接"
          trigger="click"
          visible={popVisible}
          onVisibleChange={handleVisibleChange}
        >
          <Tooltip title="点击可编辑">
            <Tag onClick={showPopover} closable onClose={handleCloseTag} {...tagColor}>
              {label}
            </Tag>
          </Tooltip>
        </Popover>
        {children}
      </span>
    );
  }, [
    attributes,
    canEditor,
    children,
    handleCloseTag,
    handleVisibleChange,
    label,
    popContent,
    popVisible,
    showPopover,
    tagColor,
  ]);

  return destTag;
};

export default TagParamText;
