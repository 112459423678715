import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { CheckOutlined } from '@ant-design/icons';

export interface Props extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  checked?: boolean;
  activeColor?: string;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Container = styled.label<{ checked: boolean; activeColor: string }>`
  display: inline-block;
  position: relative;
  padding: 10px;
  border: solid 1px #d3d7e0;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-out;
  border-color: ${({ checked, activeColor }) => (checked ? activeColor : '#d3d7e0')};
  > input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  > input[type='checkbox'] + span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 8px;
    border-color: ${({ activeColor }) => `transparent ${activeColor} ${activeColor} transparent`};
    color: #fff;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
    transform: ${({ checked }) => (checked ? 'scale(1)' : 'scale(0)')};
    transform-origin: right bottom;
    transition: all 0.3s ease-out;
    > span {
      position: absolute;
      top: -2px;
      left: -2px;
      font-size: 10px;
    }
  }
`;

function CheckContainer(props: Props) {
  const {
    checked: checkedProp,
    onChange: onChangeProp,
    children,
    activeColor = 'var(--ant-primary-color)',
    ...otherProps
  } = props;

  const inputId = useRef(`${Math.random()}`);

  const [checkedState, setCheckedState] = useState(checkedProp || false);

  const checked = checkedProp === undefined ? checkedState : checkedProp;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeProp) {
        onChangeProp(e.target.checked, e);
      }

      setCheckedState(e.target.checked);
    },
    [onChangeProp],
  );

  useEffect(() => {
    if (checkedProp !== undefined) {
      setCheckedState(checkedProp);
    }
  }, [checkedProp]);

  return (
    <Container
      checked={checked}
      activeColor={activeColor}
      htmlFor={inputId.current}
      {...otherProps}
    >
      <input id={inputId.current} type="checkbox" checked={checked} onChange={handleChange} />
      <span>
        <CheckOutlined />
      </span>
      {children}
    </Container>
  );
}

export default CheckContainer;
