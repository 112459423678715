import { css, cx } from '@emotion/css';
import { useFocused, useSelected } from 'slate-react';

const styles = {
  container: css`
    display: flex;
    align-items: center;
    margin: 4px 0;
    color: #387cff;
    cursor: pointer;
    &::before,
    &::after {
      flex: 1 1 0;
      content: '';
      border-top: 1px dashed currentColor;
    }
  `,
  text: css`
    margin: 0 8px;
  `,
  selected: css`
    background: #e6f7ff;
  `,
};

interface Props {
  attributes?: any;
  children?: any;
}

function DividingLine(props: Props) {
  const { attributes, children } = props;
  const focused = useFocused();
  const selected = useSelected();
  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={cx(styles.container, focused && selected && styles.selected)}
      >
        <span className={styles.text}>新消息分割</span>
        {children}
      </div>
    </div>
  );
}

export default DividingLine;
