import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomSlateNodeType } from '../types';

const LyEditor = {
  ...Editor,
  insertDividingLine(editor: Editor) {
    Transforms.insertNodes(editor, {
      type: CustomSlateNodeType.DIVIDING_LINE,
      children: [{ text: '' }],
    });
  },
  insertConcept(editor: Editor, label: string) {
    Transforms.insertNodes(editor, {
      type: CustomSlateNodeType.CONCEPT,
      label,
      children: [{ text: '' }],
    });
    Transforms.move(editor);
    ReactEditor.focus(editor);
  },
  insertParamText(editor: Editor, label: string, canEditor = true) {
    Transforms.insertNodes(editor, {
      type: CustomSlateNodeType.PARAMTEXT,
      label,
      children: [{ text: '' }],
      canEditor,
    });
    Transforms.move(editor, { distance: 1, unit: 'offset' });
  },
  insertParamHolder(editor: Editor, label: string) {
    Transforms.insertNodes(editor, {
      type: CustomSlateNodeType.PARAMHOLDER,
      label,
      children: [{ text: '' }],
    });
    Transforms.move(editor, { distance: 1, unit: 'offset' });
  },
};

export default LyEditor;
