import React, { useContext, useMemo } from 'react';
import { parse, stringify } from 'query-string';
import { useHref, useLocation, Path } from 'react-router-dom';

import refine, { Refinable } from '../../utils/refine';
import createNamedContext from '../../utils/createNamedContext';

/**
 * 路由 `query` `context` 值
 */
export interface RouterQueryContextValue extends Record<string, any> {}

/**
 * 路由 `query` `context`
 */
export const RouterQueryContext = createNamedContext<RouterQueryContextValue>(
  'RouterQueryContext',
  {},
);

/**
 * 简单的 `query` 解析函数
 * @param search `search`
 */
export function simpleParse(search: string) {
  return parse(search, {
    parseBooleans: true,
    arrayFormat: 'index',
  });
}

/**
 * 简单的 `query` 序列化函数
 * @param query `query`
 */
export function simpleStringify(query: RouterQueryContextValue) {
  return stringify(query, {
    arrayFormat: 'index',
  });
}

/**
 * 路由 `query` 配置 `context` 值
 */
export interface RouterQueryConfigContextValue {
  /**
   * `query` 解析函数
   * @param search `search`
   */
  parse(search: string): RouterQueryContextValue;
  /**
   * `query` 序列化函数
   * @param query `query`
   */
  stringify(query: RouterQueryContextValue): string;
}

/**
 * 路由 `query` 配置 `context`
 */
export const RouterQueryConfigContext = createNamedContext<RouterQueryConfigContextValue>(
  'RouterQueryConfigContext',
  {
    parse: simpleParse,
    stringify: simpleStringify,
  },
);

/**
 * 获取路由 `query`
 */
export function useQuery<T extends RouterQueryContextValue = RouterQueryContextValue>() {
  const query = useContext(RouterQueryContext);

  return query as T;
}

/**
 * 获取路由 `query` 配置
 */
export function useQueryConfig() {
  const config = useContext(RouterQueryConfigContext);

  return config;
}

/**
 * 跳转配置
 */
export interface PartialRouterQueryPath extends Partial<Path> {
  query?: RouterQueryContextValue;
}

/**
 * 跳转路径或跳转配置
 */
export type RouterQueryTo = string | PartialRouterQueryPath;

/**
 * 获取跳转地址
 * @param to 跳转路径或跳转配置
 */
export function useQueryHref(to: RouterQueryTo) {
  const { parse, stringify } = useQueryConfig();

  const routerTo = useMemo(() => {
    if (typeof to === 'string') {
      return to;
    }

    const { query, search, ...others } = to;
    let nextSearch = search;

    if (query) {
      const queryFromSearch = search ? parse(search) : {};

      nextSearch = stringify({ ...queryFromSearch, ...query });
    }

    if (nextSearch && nextSearch.indexOf('?') !== 0) {
      nextSearch = `?${nextSearch}`;
    }

    return {
      ...others,
      ...(nextSearch
        ? {
            search: nextSearch,
          }
        : {}),
    };
  }, [parse, stringify, to]);

  return useHref(routerTo);
}

const { Provider: RouterQueryProvider } = RouterQueryContext;
const { Provider: RouterQueryConfigProvider } = RouterQueryConfigContext;

/**
 * 路由 `query` 属性
 */
export interface RouterQueryProps extends Partial<RouterQueryConfigContextValue> {
  /**
   * 子节点或子节点渲染函数
   */
  children?: Refinable<(query: RouterQueryContextValue) => React.ReactNode>;
}

/**
 * 路由 `query`
 * @param props 路由 `query` 属性
 */
function RouterQuery(props: RouterQueryProps) {
  const { parse, stringify, children } = props;

  const { search } = useLocation();
  const parentRouterQueryConfigContextValue = useQueryConfig();
  const routerQueryConfigContextValue = useMemo(() => {
    return {
      parse: parse || parentRouterQueryConfigContextValue.parse,
      stringify: stringify || parentRouterQueryConfigContextValue.stringify,
    };
  }, [parentRouterQueryConfigContextValue, parse, stringify]);
  const routerQueryContextValue = useMemo(() => {
    return routerQueryConfigContextValue.parse(search);
  }, [routerQueryConfigContextValue, search]);

  return (
    <RouterQueryConfigProvider value={routerQueryConfigContextValue}>
      <RouterQueryProvider value={routerQueryContextValue}>
        {refine(children, routerQueryContextValue)}
      </RouterQueryProvider>
    </RouterQueryConfigProvider>
  );
}

export default RouterQuery;
