export const escapeMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#039;',
  '/': '&#x2F;',
};

export type EscapeChar = keyof typeof escapeMap;

export const escapeChars: EscapeChar[] = ['&', '<', '>', '"', "'", '/'];

export function escapeHTML(str: string) {
  const escapeReg = new RegExp(`[${escapeChars.join('')}]`, 'g');

  return str.replace(escapeReg, (a) => escapeMap[a as EscapeChar]);
}

export interface Formatter {
  markupChar: string;
  regex: RegExp;
  tag: string;
}

export const boldFormatter: Formatter = {
  markupChar: '*',
  regex: /(?:^|[\s\W_])\*(\S|\S[^\n]*?\S)\*(?=$|[[\s\W_])/g,
  tag: 'strong',
};

export const italicFormatter: Formatter = {
  markupChar: '_',
  regex: /(?:^|\s|_|[^\w\\])_(\S|\S[^\n]*?\S)_(?=$|[[\s\W_])/g,
  tag: 'em',
};

export const strikeThroughFormatter: Formatter = {
  markupChar: '~',
  regex: /(?:^|[\s\W_])~(\S|\S[^\n]*?\S)~(?=$|[[\s\W_])/g,
  tag: 'del',
};

export const defaultFormatters: Formatter[] = [
  boldFormatter,
  italicFormatter,
  strikeThroughFormatter,
];

export function format(str: string, formatter: Formatter) {
  const { markupChar, regex, tag } = formatter;

  if (!regex.test(str)) {
    return str;
  }

  return str.replace(regex, (subString, other) => {
    const pre = subString.charAt(0) !== markupChar ? subString.charAt(0) : '';

    return `${pre}<${tag}>${other}</${tag}>`;
  });
}

export function toSafeHTML(str: string, formatters: Formatter[]) {
  return formatters.reduce(format, escapeHTML(str));
}

export function toSafeHTMLWithLineBreaks(str: string, formatters: Formatter[]) {
  return toSafeHTML(str, formatters).trim().replace(/\n/g, '<br>');
}

export function whatsAppMessageParser(str: string, formatters = defaultFormatters) {
  return toSafeHTMLWithLineBreaks(str, formatters);
}

export default whatsAppMessageParser;
