import { Descendant, Text } from 'slate';
import { CustomSlateNodeType } from '../types';

function getSegmentsLength(descendants: Descendant[] = [], conceptLength = 20): number {
  try {
    return descendants.reduce((res, element) => {
      if (Text.isText(element)) {
        return res + element.text.length;
      }

      if (element.type === CustomSlateNodeType.CONCEPT) {
        return res + conceptLength;
      }

      if (element.type === CustomSlateNodeType.PARAGRAPH) {
        return res + getSegmentsLength(element.children);
      }

      return res;
    }, 0);
  } catch (err) {
    return 0;
  }
}

export default getSegmentsLength;
