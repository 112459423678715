import { Menu, Dropdown, Button, DropDownProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSlateStatic } from 'slate-react';
import LyEditor from '../utils/LyEditor';

export interface Concept {
  id: string;
  label: string;
}

interface Props extends Omit<DropDownProps, 'overlay'> {
  concepts: Concept[];
}

function ConceptMenu(props: Props) {
  const { concepts, ...otherProps } = props;

  const editor = useSlateStatic();

  return concepts.length === 0 ? null : (
    <Dropdown
      overlay={
        <Menu
          onClick={({ key }) => {
            LyEditor.insertConcept(editor, key);
          }}
          items={concepts.map(({ id, label }) => {
            return {
              key: id,
              label: (
                <Button size="small" type="link">
                  {label}
                </Button>
              ),
            };
          })}
        />
      }
      {...otherProps}
    >
      <Button type="link">
        参数 <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export default ConceptMenu;
