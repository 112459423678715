import React, { useMemo } from 'react';
import moment, { Moment, unitOfTime } from 'moment';
import styled from '@emotion/styled';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import refine from '../../utils/refine';

const { RangePicker } = DatePicker;

/**
 * 时间区间值
 */
type Range = [Moment, Moment];

/**
 * 快捷选项
 */
export interface QuickAction {
  /**
   * 唯一键值
   */
  key: string;
  /**
   * 快捷选项显示标签
   */
  label: React.ReactNode;
  /**
   * 预设时间区间
   */
  range: Range | (() => Range);
}

const PickerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 22px;
  }
`;

const Picker = styled(RangePicker)`
  &:not(:last-child) {
    margin-right: 22px;
  }
`;

const PickerQuickAction = styled.span<{ active?: boolean; disabled?: boolean }>`
  display: inline-flex;
  font-size: 14px;
  color: ${(props) => (props.active ? 'var(--ant-primary-color)' : '#84868c')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

/**
 * 判断是否是同一时间区间
 * @param a a 时间区间
 * @param b b 时间区间
 * @param unit 判断粒度
 */
export function isSameRange(a: Range, b: Range, unit: unitOfTime.StartOf = 'milliseconds') {
  return a[0].isSame(b[0], unit) && a[1].isSame(b[1], unit);
}

/**
 * 快捷选择区域位置
 */
export type QuickActionsPlacement = 'start' | 'end';

/**
 * 快捷时间区间选择器属性
 */
export type QuickRangePickerProps = RangePickerProps & {
  showTime?: boolean;
  /**
   * 快捷选择区域位置
   */
  quickActionsPlacement?: QuickActionsPlacement;
  /**
   * 快捷选项
   */
  quickActions?: QuickAction[];
  /**
   * 快捷选项点击回调
   */
  onQuickActionClick?: (action: QuickAction) => void;
};

/**
 * 快捷时间区间选择器
 * @param props 快捷时间区间选择器属性
 */
function QuickRangePicker(props: QuickRangePickerProps) {
  const {
    quickActionsPlacement = 'start',
    quickActions = [],
    onQuickActionClick,
    format,
    value,
    onChange,
    className,
    style,
    disabled,
    showTime,
    ...otherProps
  } = props;

  const unit: unitOfTime.StartOf = showTime ? 'seconds' : 'days';

  const formatString = useMemo(() => {
    if (format === undefined) {
      const format = showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';

      return [format, format] as [string, string];
    }

    const formats = Array.isArray(format) ? format : [format, format];

    return formats.map((format) => {
      return refine(format, moment());
    }) as [string, string];
  }, [format, showTime]);

  const picker = (
    <Picker
      {...otherProps}
      disabled={disabled}
      format={formatString}
      value={value}
      onChange={onChange}
    />
  );

  const actions = quickActions.map((action) => {
    const { key, label, range } = action;

    const actionRange = refine(range);
    const active =
      value && value[0] && value[1] ? isSameRange(value as Range, actionRange, unit) : false;
    function handleActive() {
      if (disabled) {
        return;
      }

      if (!active && onChange) {
        onChange(actionRange, formatString);
      }

      if (onQuickActionClick) {
        onQuickActionClick(action);
      }
    }

    return (
      <PickerQuickAction
        key={key}
        active={active}
        disabled={disabled === true}
        onClick={onChange && handleActive}
      >
        {label}
      </PickerQuickAction>
    );
  });

  return (
    <PickerWrapper className={className} style={style}>
      {quickActionsPlacement === 'end' && picker}
      <div>{actions}</div>
      {quickActionsPlacement === 'start' && picker}
    </PickerWrapper>
  );
}

export default QuickRangePicker;
