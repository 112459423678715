import { RenderLeafProps } from 'slate-react';

function Leaf(props: RenderLeafProps) {
  const { leaf, attributes, children: childrenProp } = props;

  const { bold, italic } = leaf;

  let children = childrenProp;

  if (bold) {
    children = <strong>{children}</strong>;
  }

  if (italic) {
    children = <em>{children}</em>;
  }

  return <span {...attributes}>{children}</span>;
}

export default Leaf;
