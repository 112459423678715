import TemplateTagParamHolder from '../renders/TemplateParamHolder';
import { CustomSlateNodeType, LySlatePlugin } from '../types';
import withParamHolder from './slatePlugins/withParamHolder';

const templateParamHolderPlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.PARAMHOLDER,
  slatePlugin: withParamHolder,
  processComponent: (props) => {
    return <TemplateTagParamHolder {...props} />;
  },
};

export default templateParamHolderPlugin;
