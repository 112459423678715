import withParamText from './slatePlugins/withParamText';
import TagParamText from '../renders/TagParamText';
import { CustomSlateNodeType, LySlatePlugin } from '../types';

const paramTextPlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.PARAMTEXT,
  slatePlugin: withParamText,
  processComponent: (props) => {
    return <TagParamText {...props} />;
  },
};

export default paramTextPlugin;
