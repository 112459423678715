import { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Table, TableProps } from 'antd';
import { useSize } from 'ahooks';

export interface ScrollTableProps<T extends object> extends TableProps<T> {}

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const paginationHeight = 64;

function ScrollTable<T extends object>(props: ScrollTableProps<T>) {
  const { pagination, className, style, ...otherProps } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { height = 0 } = useSize(wrapperRef) || {};

  const { height: theadHeight = 0 } =
    useSize(wrapperRef.current?.querySelector('thead') || null) || {};

  const withPagination = pagination !== false;

  const scroll = useMemo<Required<TableProps<T>>['scroll']>(() => {
    const restHeight = theadHeight + (withPagination ? paginationHeight : 0);

    return {
      y: height - restHeight,
    };
  }, [height, theadHeight, withPagination]);

  return (
    <Wrapper ref={wrapperRef} className={className} style={style}>
      <Table scroll={scroll} pagination={pagination} {...otherProps} />
    </Wrapper>
  );
}

export default ScrollTable;
