import { useCallback } from 'react';
import { useField, FieldMetaProps, FieldHelperProps } from 'formik';

export interface SmartFieldProps<T> {
  field: {
    name: string;
    value: T;
    onChange(eventOrValue: T | React.ChangeEvent<any>): void;
    onBlur(event?: React.FocusEvent<any>): void;
  };
  meta: FieldMetaProps<T>;
  helpers: FieldHelperProps<T>;
}

function useSmartField<T>(
  name: string,
  validate?: (value: T) => undefined | string | Promise<any>,
): SmartFieldProps<T> {
  const [field, meta, helpers] = useField<T>({
    name,
    validate,
  });

  const { value } = field;

  const onChange = useCallback(
    (eventOrValue: T | React.ChangeEvent<any>) => {
      const { target: { checked = eventOrValue, value = checked } = {} } = (eventOrValue ||
        {}) as any;

      helpers.setValue(value as T);
    },
    [helpers],
  );

  const onBlur = useCallback(() => {
    helpers.setTouched(true);
  }, [helpers]);

  return {
    field: {
      name,
      value,
      onChange,
      onBlur,
    },
    meta,
    helpers,
  };
}

export default useSmartField;
