import React from 'react';

/**
 * `RenderProps` 属性名
 */
export type RenderPropName = 'as' | 'render' | 'children';

/**
 * 根据组件属性 `TProps` 构造不含 `RenderPropName` 属性名的类型
 * @template TProps 组件属性
 */
export type WithoutRenderProps<TProps> = Omit<TProps, RenderPropName>;

/**
 * 使用组件属性 `TProps` 作为渲染属性的渲染函数
 * @template TProps 组件属性
 */
export interface RenderFunction<TProps> {
  (props: TProps): React.ReactNode;
}

/**
 * 根据组件 `TComponent` 和组件属性 `TProps` 构造 `RenderProps` 模式属性的类型
 * @template TComponent 组件
 * @template TProps 组件属性
 */
export interface RenderProps<
  TComponent extends React.ElementType = React.ElementType,
  TProps extends WithoutRenderProps<React.ComponentProps<TComponent>> = WithoutRenderProps<
    React.ComponentProps<TComponent>
  >,
> {
  /**
   * 渲染组件
   *
   * 使用 `as` 组件进行渲染，
   * `as` 属性存在会忽略 `render` 和 `children` 属性的功能，
   * 并将 `children` 属性作为指定组件的 `children` 属性传递
   */
  as: TComponent;
  /**
   * 渲染函数
   *
   * 使用 `render` 渲染函数进行渲染
   * 此属性存在会忽略 `children` 属性
   */
  render: RenderFunction<WithoutRenderProps<TProps>>;
  /**
   * 渲染函数或子节点
   *
   * 当 `children` 是渲染函数时，使用 `children` 渲染函数进行渲染，
   * 当 `children` 不是渲染函数时，直接将 `children` 做为子节点
   */
  children: RenderFunction<WithoutRenderProps<TProps>> | React.ReactNode;
}

/**
 * 根据组件 `TComponent` 和组件属性 `TProps` 构造附加 `RenderProps` 属性的类型
 * @template TComponent 组件
 * @template TProps 组件属性
 */
export type WithRenderProps<
  TComponent extends React.ElementType = React.ElementType,
  TProps extends WithoutRenderProps<React.ComponentProps<TComponent>> = WithoutRenderProps<
    React.ComponentProps<TComponent>
  >,
> = Partial<RenderProps<TComponent, TProps>> & TProps;

/**
 * 使用组件 `TComponent` 和 组件属性 `TProps` 作为渲染属性的 `RenderProps` 渲染函数
 * @template TComponent 组件
 * @template TProps 组件属性
 * @param props 渲染属性
 */
export function render<
  TComponent extends React.ElementType = React.ElementType,
  TProps extends WithoutRenderProps<React.ComponentProps<TComponent>> = WithoutRenderProps<
    React.ComponentProps<TComponent>
  >,
>(props: WithRenderProps<TComponent, TProps>): React.ReactNode {
  const { as, render, children, ...childrenProps } = props;

  if (as) {
    // FIXME: 此处强制转换为 `React.ElementType`，否则在下面返回语句处会抛错 ts(2322) 错误，
    //        找到解决方法再修改此处强制转换
    const Component = as as React.ElementType;

    return <Component {...childrenProps}>{children}</Component>;
  }

  if (render) {
    return render(childrenProps);
  }

  if (typeof children === 'function') {
    return children(childrenProps);
  }

  return children;
}

/**
 * `RenderProps` 渲染器
 * @template TComponent 组件
 * @template TProps 组件属性
 * @param props 渲染属性
 */
function Render<
  TComponent extends React.ElementType = React.ElementType,
  TProps extends WithoutRenderProps<
    React.ComponentProps<TComponent>
  > = React.ComponentProps<TComponent>,
>(props: WithRenderProps<TComponent, TProps>) {
  return <>{render(props)}</>;
}

export default Render;
