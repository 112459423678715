import { useCallback, useMemo } from 'react';
import { css } from '@emotion/css';
import { Tag } from 'antd';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

const styles = {
  tag: css`
    color: #705cf2;
    span {
      color: #705cf2 !important;
    }
  `,
};

interface Props {
  element: any;
  attributes: any;
  children: any;
}

const TemplateTagParamText = (props: Props) => {
  const { element, attributes, children } = props;
  const { label } = element;

  const editor = useSlate();

  const handleCloseTag = useCallback(() => {
    const currentPath = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: currentPath });
  }, [editor, element]);

  const destTag = useMemo(() => {
    return (
      <span contentEditable={false}>
        <Tag
          color="#E6E4F2"
          className={styles.tag}
          closable
          onClose={handleCloseTag}
          {...attributes}
        >
          {label}
          {children}
        </Tag>
      </span>
    );
  }, [attributes, children, handleCloseTag, label]);

  return destTag;
};

export default TemplateTagParamText;
