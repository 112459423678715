import { Descendant, Text } from 'slate';
import { CustomSlateNodeType } from '../TemplateEditor';
import { whatsAppMessageParser } from './whatsAppMessageParser';

export function descendants2WhatsAppMessage(descendants: Descendant[]) {
  const conceptTagPlaceHolder = 'x@@conceptx';

  function getTexts(descendants: Descendant[]): string {
    return descendants.reduce((res, cur) => {
      if (cur.type === CustomSlateNodeType.PARAGRAPH) {
        return res + getTexts(cur.children);
      }

      if (Text.isText(cur)) {
        return res + cur.text;
      }

      if (cur.type === CustomSlateNodeType.CONCEPT) {
        const { label } = cur;

        return `${res}${conceptTagPlaceHolder}${label}${conceptTagPlaceHolder}`;
      }

      return res;
    }, '');
  }

  const conceptReg = new RegExp(`${conceptTagPlaceHolder}(.*?)${conceptTagPlaceHolder}`, 'g');

  function resetConceptTag(str: string) {
    return str.replace(conceptReg, (_, content) => {
      return `<var>${content}</var>`;
    });
  }

  return resetConceptTag(whatsAppMessageParser(getTexts(descendants)));
}

export default descendants2WhatsAppMessage;
