import { Editor } from 'slate';
import { CustomSlateNodeType } from '../../types';

function withConcept(editor: Editor): Editor {
  const conceptEditor = editor;

  const { isInline, isVoid } = editor;

  conceptEditor.isInline = (element) => {
    return element.type === CustomSlateNodeType.CONCEPT ? true : isInline(element);
  };

  conceptEditor.isVoid = (element) => {
    return element.type === CustomSlateNodeType.CONCEPT ? true : isVoid(element);
  };

  return conceptEditor;
}

export default withConcept;
