import React from 'react';
import { RenderElementProps } from 'slate-react';
import { LySlatePlugin } from '../types';
import SlateElementRender from './SlateElementRender';

function connectRenderElementMap(
  renderElementMap: Record<string, LySlatePlugin['processComponent']>,
  DefaultElementRenderComponent?: React.ComponentType,
) {
  return function (props: RenderElementProps) {
    return (
      <SlateElementRender
        renderElementMap={renderElementMap}
        {...props}
        DefaultElementRenderComponent={DefaultElementRenderComponent}
      />
    );
  };
}

export default connectRenderElementMap;
