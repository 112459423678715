import Icon, { FilePdfOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { HTMLAttributes, ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { Descendant } from 'slate';
import { descendants2WhatsAppMessage } from './contentTransfer';
import PhoneSvg from './assets/phone.svg?component';
import ShareSvg from './assets/share.svg?component';
import ImageSvg from './assets/image.svg?component';
import VideoSvg from './assets/video.svg?component';

const Message = styled.div`
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const Footer = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 12px;
  color: #84868c;
`;

interface WhatsAppTextHeader {
  type: 'TEXT';
  text: string;
}

interface WhatsAppMaterialHeader {
  type: 'IMAGE' | 'VIDEO' | 'DOCUMENT';
  example: string;
  fileName: string;
  previewUrl: string;
}

type WhatsAppHeader = WhatsAppTextHeader | WhatsAppMaterialHeader;

interface WhatsAppQuickReplyButton {
  type: 'QUICK_REPLY';
  text: string;
}

interface WhatsAppActivityUrlButton {
  type: 'ACTIVITY';
  optionType: 'URL';
  text: string;
}

interface WhatsAppActivityPhoneNumButton {
  type: 'ACTIVITY';
  optionType: 'PHONE_NUM';
  text: string;
}

type WhatsAppActivityButton = WhatsAppActivityUrlButton | WhatsAppActivityPhoneNumButton;

type WhatsAppButton = WhatsAppQuickReplyButton | WhatsAppActivityButton;

export interface WhatsAppPreviewTemplate {
  header?: WhatsAppHeader;
  footer?: string;
  contents?: Descendant[];
  buttons?: WhatsAppButton[];
}

interface PdfRender {
  (url: string, onLoad: () => void): JSX.Element;
}

export interface WhatsAppTemplatePreviewProps extends HTMLAttributes<HTMLDivElement> {
  template: WhatsAppPreviewTemplate;
  pdfRender: PdfRender;
  assets: {
    pdfTitleText: string;
    pdfNameText: string;
    contentPlaceholder: ReactNode;
  };
}

function WhatsAppTemplatePreview(props: WhatsAppTemplatePreviewProps) {
  const {
    template,
    pdfRender,
    assets: { pdfTitleText, pdfNameText, contentPlaceholder },
    ...otherProps
  } = props;

  const { header, contents, footer, buttons = [] } = template;

  return (
    <div {...otherProps}>
      <Message>
        {header && (
          <Header
            header={header}
            pdfTitleText={pdfTitleText}
            pdfNameText={pdfNameText}
            pdfRender={pdfRender}
          />
        )}
        <Content contents={contents} placeholder={contentPlaceholder} />
        {footer && <Footer>{footer}</Footer>}
        {buttons.length > 0 && buttons[0].type === 'ACTIVITY' && (
          <ActivityButtons buttons={buttons as WhatsAppActivityButton[]} />
        )}
      </Message>
      {buttons.length > 0 && buttons[0].type === 'QUICK_REPLY' && (
        <QuickReplyButtons buttons={buttons as WhatsAppQuickReplyButton[]} />
      )}
    </div>
  );
}

const Title = styled.h4`
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
  color: #232426;
`;

const Image = styled.div<{ url?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  margin: -12px -12px 12px;
  background: ${({ url }) => (url ? `center / cover no-repeat url(${url})` : '#D6E8FF')};
  > .anticon {
    font-size: 40px;
    color: var(--ant-primary-color);
  }
`;

interface HeaderProps {
  header: Required<WhatsAppPreviewTemplate>['header'];
  pdfTitleText: string;
  pdfNameText: string;
  pdfRender: PdfRender;
}

function Header(props: HeaderProps) {
  const { header, pdfTitleText, pdfNameText, pdfRender } = props;

  if (header.type === 'TEXT') {
    const { text } = header;

    if (!text) {
      return null;
    }

    return <Title>{text}</Title>;
  }

  const { type, fileName, previewUrl: url } = header;

  if (type === 'IMAGE') {
    if (url) {
      return <Image url={url} />;
    }

    return (
      <Image>
        <Icon component={ImageSvg} />
      </Image>
    );
  }

  if (type === 'VIDEO') {
    if (url) {
      return <Image url={url} />;
    }

    return (
      <Image>
        <Icon component={VideoSvg} />
      </Image>
    );
  }

  if (type === 'DOCUMENT') {
    return (
      <PdfPreview
        url={url}
        fileName={fileName}
        titleText={pdfTitleText}
        nameText={pdfNameText}
        pdfRender={pdfRender}
      />
    );
  }

  return null;
}

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;
  margin: -12px -12px 12px;
  background: #d6e8ff;
  > div {
    flex: 1 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  > p {
    height: 30px;
    line-height: 30px;
    margin-bottom: 0;
    padding: 0 20px;
    background: #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > .anticon {
      margin-right: 4px;
    }
  }
`;

const PdfWrapper = styled.div`
  background: #fff;
  display: block !important;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface PdfPreviewProps {
  url?: string;
  fileName?: string;
  titleText: string;
  nameText: string;
  pdfRender: PdfRender;
}

function PdfPreview(props: PdfPreviewProps) {
  const { url, fileName, titleText, nameText, pdfRender } = props;

  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    return () => {
      setLoaded(false);
    };
  }, [url]);

  return (
    <PdfContainer>
      {url ? <PdfWrapper>{pdfRender(url, () => setLoaded(true))}</PdfWrapper> : null}
      {!loaded && <div>{titleText}</div>}
      <p title={fileName || `${nameText}.pdf`}>
        <FilePdfOutlined />
        {fileName || `${nameText}.pdf`}
      </p>
    </PdfContainer>
  );
}

const ContentContainer = styled.p`
  var {
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 4px;
    padding: 0 8px;
    font-style: inherit;
    background: #e6e4f2;
    border-radius: 2px;
  }
`;

interface ContentProps {
  contents: WhatsAppPreviewTemplate['contents'];
  placeholder: ReactNode;
}

function Content(props: ContentProps) {
  const { contents, placeholder } = props;

  const contentHTML = useMemo(() => {
    if (contents) {
      return descendants2WhatsAppMessage(contents);
    }

    return undefined;
  }, [contents]);

  if (contentHTML) {
    // eslint-disable-next-line react/no-danger
    return <ContentContainer dangerouslySetInnerHTML={{ __html: contentHTML }} />;
  }

  return <ContentContainer>{placeholder}</ContentContainer>;
}

const ActivityButtonsContainer = styled.div`
  padding-top: 6px;
  border-top: solid 1px #a2a2a2;
  margin-bottom: -6px;
  margin-top: 4px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    color: var(--ant-primary-color);
    cursor: pointer;
    > .anticon {
      margin-right: 8px;
    }
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0;
    }
  }
`;

interface ActivityButtonsProps {
  buttons: WhatsAppActivityButton[];
}

function ActivityButtons(props: ActivityButtonsProps) {
  const { buttons } = props;

  const validButtons = buttons.filter(({ text }) => text);

  if (validButtons.length === 0) {
    return null;
  }

  return (
    <ActivityButtonsContainer>
      {validButtons.map((button) => {
        const { optionType, text } = button;

        return (
          <div>
            {
              {
                URL: <Icon component={ShareSvg} />,
                PHONE_NUM: <Icon component={PhoneSvg} />,
              }[optionType]
            }
            <p title={text}>{text}</p>
          </div>
        );
      })}
    </ActivityButtonsContainer>
  );
}

const QuickReplyButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(50% - 6px);
    max-width: 100%;
    height: 30px;
    margin: 6px 0 0 6px;
    padding: 0 16px;
    color: var(--ant-primary-color);
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
    > span {
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
`;

interface QuickReplyButtonsProps {
  buttons: WhatsAppQuickReplyButton[];
}

function QuickReplyButtons(props: QuickReplyButtonsProps) {
  const { buttons } = props;

  const validButtons = buttons.filter(({ text }) => text);

  if (validButtons.length === 0) {
    return null;
  }

  return (
    <QuickReplyButtonsContainer>
      {validButtons.map(({ text }) => {
        return (
          <div>
            <span title={text}>{text}</span>
          </div>
        );
      })}
    </QuickReplyButtonsContainer>
  );
}

export default WhatsAppTemplatePreview;

export { descendants2WhatsAppMessage } from './contentTransfer';

export { whatsAppMessageParser } from './whatsAppMessageParser';
