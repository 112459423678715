import { Button, Result } from 'antd';
import React, { PureComponent, ErrorInfo, ReactNode } from 'react';

const defaultFallback = () => {
  return (
    <Result
      status="500"
      title="应用加载失败"
      subTitle="请稍后尝试手动重新加载"
      extra={
        <Button type="primary" onClick={() => window.location.reload()}>
          立即重新加载
        </Button>
      }
    />
  );
};

/**
 * 替换渲染函数
 */
export interface FallbackFunction {
  (error: Error, reset: () => void): React.ReactNode;
}

/**
 * 错误边界属性
 */
export interface ErrorBoundaryProps {
  /**
   * 抛错后替换渲染节点
   */
  fallback?: FallbackFunction | React.ReactNode;
  onDidCatch?: (error: Error, info: ErrorInfo) => void;
  children: ReactNode;
}

/**
 * 错误边界正常状态
 */
export interface ErrorBoundaryNormalState {
  /**
   * 是否抛除错误
   */
  hasError: false;
  /**
   * 抛出错误信息
   */
  error: null;
}

/**
 * 错误边界错误状态
 */
export interface ErrorBoundaryErrorState {
  /**
   * 是否抛除错误
   */
  hasError: true;
  /**
   * 抛出错误信息
   */
  error: Error;
}

/**
 * 错误边界状态
 */
export type ErrorBoundaryState = ErrorBoundaryNormalState | ErrorBoundaryErrorState;

/**
 * 错误边界
 */
class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const {
      props: { onDidCatch },
    } = this;

    if (onDidCatch) {
      onDidCatch(error, info);
    }
  }

  reset = () => {
    this.setState({
      hasError: false,
      error: null,
    });
  };

  render() {
    const {
      props: { fallback = defaultFallback, children },
      state,
    } = this;

    if (state.hasError) {
      if (typeof fallback === 'function') {
        return fallback(state.error, this.reset) as React.ReactNode;
      }

      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
