import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { Form, Input, Popover, Tag, Tooltip } from 'antd';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

const { Search } = Input;

type ValidateStatuses = 'success' | 'warning' | 'error' | 'validating' | '';
type FormItemError = { validateStatus: ValidateStatuses; errorMsg: string | null };

const INIT_ERROR: FormItemError = { validateStatus: 'success', errorMsg: null };

const ContentLimit = 50;

const styles = {
  selected: css`
    box-shadow: 0 0 0 3px #b4d5ff;
  `,
  tag: css`
    color: #705cf2;
    margin: 0 4px;
    span {
      color: #705cf2 !important;
    }
  `,
  defaultTag: css`
    background-color: #f0f2f5;
    border: 0;
    color: #515359;
    span {
      color: #515359 !important;
    }
  `,
  ml4: css`
    margin-left: 4px; ;
  `,
};

interface Props {
  element: any;
  attributes: any;
  children: any;
}

const TagParamHolder = (props: Props) => {
  const { element, children } = props;
  const { label } = element;

  const editor = useSlate();

  const [inputUrl, setInputUrl] = useState<string>();
  const [linkInputError, setLinkInputError] = useState<FormItemError>(INIT_ERROR);
  const [popVisible, setPopVisible] = useState<boolean>(false);

  const changeParamHolderContent = useCallback(
    (value: string) => {
      if (!value) {
        setLinkInputError({
          validateStatus: 'error',
          errorMsg: '请输入参数内容',
        });
        return;
      }
      if (value.length > ContentLimit) {
        setLinkInputError({
          validateStatus: 'error',
          errorMsg: `自定义内容最多${ContentLimit}个字`,
        });
        return;
      }
      const currentPath = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        {
          ...element,
          label: value,
        },
        { at: currentPath },
      );
      setInputUrl('');
      setPopVisible(false);
    },
    [editor, element],
  );

  const initError = useCallback(() => {
    setLinkInputError(INIT_ERROR);
  }, []);

  const handleInputChange = useCallback(
    (e: any) => {
      initError();
      setInputUrl(e.target.value);
    },
    [initError],
  );

  const handleCloseTag = useCallback(() => {
    const currentPath = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: currentPath });
  }, [editor, element]);

  const handleVisibleChange = useCallback((visible: boolean) => {
    setPopVisible(visible);
  }, []);
  const showPopover = useCallback(() => {
    setPopVisible(true);
  }, []);

  const popContent = useMemo(
    () => (
      <Form.Item validateStatus={linkInputError.validateStatus} help={linkInputError.errorMsg}>
        <Search
          value={inputUrl}
          onChange={handleInputChange}
          onSearch={changeParamHolderContent}
          onBlur={initError}
          onClick={initError}
          enterButton="确定"
          placeholder="请输入自定义内容..."
          allowClear
          addonAfter={
            <span className={styles.ml4}>{`${
              inputUrl ? inputUrl.length : 0
            }/${ContentLimit}`}</span>
          }
        />
      </Form.Item>
    ),
    [
      changeParamHolderContent,
      handleInputChange,
      initError,
      inputUrl,
      linkInputError.errorMsg,
      linkInputError.validateStatus,
    ],
  );

  const tagColor = useMemo(() => {
    const initialContent = ['自定义内容'];
    return initialContent.includes(label)
      ? {
          color: 'red',
        }
      : {
          color: '#E6E4F2',
          className: styles.tag,
        };
  }, [label]);

  const destTag = useMemo(() => {
    return (
      <span contentEditable={false}>
        <Popover
          content={popContent}
          title="自定义内容"
          trigger="click"
          visible={popVisible}
          onVisibleChange={handleVisibleChange}
        >
          <Tooltip title="点击可编辑">
            <Tag onClick={showPopover} closable onClose={handleCloseTag} {...tagColor}>
              {label}
            </Tag>
          </Tooltip>
        </Popover>
        {children}
      </span>
    );
  }, [
    children,
    handleCloseTag,
    handleVisibleChange,
    label,
    popContent,
    popVisible,
    showPopover,
    tagColor,
  ]);

  return destTag;
};

export default TagParamHolder;
