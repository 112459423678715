import { useCallback, useState } from 'react';
import { Popconfirm, PopconfirmProps, Switch, SwitchProps } from 'antd';

type OnChangeParameters = Parameters<Required<SwitchProps>['onChange']>;

export interface StatusSwitchProps extends Omit<SwitchProps, 'onChange'> {
  onChange?: (...params: OnChangeParameters) => Promise<any>;
  popConfirmProps?: PopconfirmProps;
}

function StatusSwitch(props: StatusSwitchProps) {
  const { checked, onChange, popConfirmProps, ...otherProps } = props;

  const [loading, setLoading] = useState(false);

  const handler = useCallback(
    (...params: OnChangeParameters) => {
      if (!onChange) {
        return;
      }

      setLoading(true);

      onChange(...params).finally(() => {
        setLoading(false);
      });
    },
    [onChange],
  );

  if (!popConfirmProps) {
    return <Switch loading={loading} checked={checked} onChange={handler} {...otherProps} />;
  }

  return (
    <Popconfirm {...popConfirmProps} onConfirm={(e) => handler(!checked, e as any)}>
      <Switch loading={loading} checked={checked} {...otherProps} />
    </Popconfirm>
  );
}

export default StatusSwitch;
