import withParamText from './slatePlugins/withParamText';
import TemplateTagParamText from '../renders/TemplateTagParamText';
import { CustomSlateNodeType, LySlatePlugin } from '../types';

const templateParamTextPlugin: LySlatePlugin = {
  elementType: CustomSlateNodeType.PARAMTEXT,
  slatePlugin: withParamText,
  processComponent: (props) => {
    return <TemplateTagParamText {...props} />;
  },
};

export default templateParamTextPlugin;
