interface Props {
  attributes: any;
  children: any;
}

function DefaultElement(props: Props) {
  const { attributes, children } = props;
  return <div {...attributes}>{children}</div>;
}

export default DefaultElement;
