import { HTMLAttributes, ReactNode, useRef } from 'react';
import styled from '@emotion/styled';
import { useSize } from 'ahooks';

const Container = styled.div<{ height: string }>`
  position: relative;
  height: ${({ height }) => height};
  overflow: hidden;
  transition: all 0.2s;
`;

export interface FoldProps extends HTMLAttributes<HTMLElement> {
  isfold: boolean;
  fixedCount: number;
  nodeList: ReactNode[];
  defaultFixedHeight?: number;
}

function Fold(props: FoldProps) {
  const { isfold, fixedCount, nodeList, children, defaultFixedHeight = 0, ...otherProps } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const fixedContentRef = useRef<HTMLDivElement>(null);

  const { height: contentHeight = 0 } = useSize(contentRef) || {};

  const { height: fixedHeight = defaultFixedHeight } = useSize(fixedContentRef) || {};

  const fixedContent = <div ref={fixedContentRef}>{nodeList.slice(0, fixedCount)}</div>;

  const otherContent = nodeList.slice(fixedCount);

  const content = (
    <div ref={contentRef}>
      {fixedContent}
      {otherContent}
    </div>
  );

  const containerHeight = isfold ? `${fixedHeight}px` : `${contentHeight}px`;

  return (
    <Container height={containerHeight} {...otherProps}>
      {content}
      {children}
    </Container>
  );
}

export default Fold;
